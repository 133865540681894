<template>
  <v-app-bar absolute app flat height="75">
    <v-btn
      fab
      outlined
      v-if="showBack && $vuetify.breakpoint.smAndDown"
      x-small
      color="primary"
      @click.native="$router.back()"
      style="box-shadow: none"
      class="mr-2"
    >
      <v-icon class="mt-0 ml-1">mdi-arrow-left</v-icon>
    </v-btn>
    <v-btn
      v-if="!$vuetify.breakpoint.smAndDown"
      fab
      x-small
      color="primary"
      @click.native="btnMenu()"
      style="box-shadow: none"
    >
      <v-icon class="mt-0" color="white">mdi-dots-horizontal</v-icon>
    </v-btn>

    <v-toolbar-title
      style="text-transform: uppercase; border-bottom: 2px solid"
      class="primary--text ml-5"
      v-text="
        $t(
          $vuetify.breakpoint.xsOnly
            ? 'routes_xs.' + $route.name
            : 'routes.' + $route.name
        )
      "
    />
    <v-spacer />

    <div class="mx-3" />
    <div v-if="$vuetify.breakpoint.smAndUp">
      <a
        v-if="$store.getters['auth/rrss'].hasOwnProperty('instagram')"
        :href="
          `https://www.instagram.com/${$store.getters['auth/rrss'].instagram}/`
        "
        target="blank"
        ><v-btn fab x-small color="primary" depressed
          ><v-icon>mdi-instagram</v-icon></v-btn
        ></a
      >

      <a
        v-if="$store.getters['auth/rrss'].hasOwnProperty('tiktok')"
        :href="`https://www.tiktok.com/@${$store.getters['auth/rrss'].tiktok}/`"
        target="blank"
      >
        <v-btn fab x-small color="primary" style="margin-left: 1em" depressed
          ><v-icon>$tik_tok</v-icon></v-btn
        >
      </a>
      <a
        v-if="$store.getters['auth/rrss'].hasOwnProperty('twitter')"
        :href="
          `https://www.twitter.com/${$store.getters['auth/rrss'].twitter}/`
        "
        target="blank"
      >
        <v-btn fab x-small color="primary" style="margin-left: 1em" depressed
          ><v-icon>mdi-twitter</v-icon></v-btn
        ></a
      ><a
        v-if="$store.getters['auth/rrss'].hasOwnProperty('facebook')"
        :href="
          `https://www.facebook.com/${$store.getters['auth/rrss'].facebook}/`
        "
        target="blank"
      >
        <v-btn fab x-small color="primary" style="margin-left: 1em" depressed
          ><v-icon>$facebook</v-icon></v-btn
        ></a
      >
    </div>
    <div v-if="$vuetify.breakpoint.smAndUp">
      <dropdown-language />
    </div>
    <v-menu bottom left offset-y absolute origin="top right">
      <template v-slot:activator="{ attrs, on }">
        <v-btn min-width="0" fab outlined v-bind="attrs" v-on="on" x-small>
          <v-badge
            color="primary"
            v-if="$store.getters['auth/notificationdCount'] > 0"
            v-bind:class="[
              {
                inset_10:
                  $store.getters['auth/notificationdCount'] > 9 &&
                  $store.getters['auth/notificationdCount'] < 100,
              },
              { inset_100: $store.getters['auth/notificationdCount'] > 99 },
            ]"
          >
            <template v-slot:badge>
              {{ $store.getters["auth/notificationdCount"] }}
            </template>
            <v-icon style="margin-left: 3px; margin-top: 0px">mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else style="margin-left: 3px; margin-top: 0px">
            mdi-bell
          </v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" nav>
        <notification-item
          v-for="(n, i) in notifications.slice(0, 4)"
          :key="`item-${i}`"
          :item="n"
          v-bind:style="[
            {
              'max-width': $vuetify.breakpoint.xsOnly ? '330px' : '1000px',
            },
          ]"
        />
        <v-list-item>
          <v-row>
            <v-col class="pa-0">
              <v-btn
                class="mt-1"
                text
                small
                left
                @click="$router.push({ name: 'notifications' })"
              >
                {{ $t("see_more") }}
                <v-span class="pl-1" v-if="notifications.length > 4">
                  +{{ notifications.length - 4 }}
                </v-span>
              </v-btn></v-col
            >
            <v-col
              v-if="notifications.length > 0"
              style="display: flex; justify-content: flex-end"
              class="pa-0"
            >
              <v-btn class="mt-1" text small right @click="allMark()">
                {{ $t("notifications.read") }}
              </v-btn></v-col
            >
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      style="width: 100px !important"
      v-model="menu"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn min-width="0" fab text v-on="on" small>
          <v-avatar size="36">
            <img :src="$store.getters['auth/userImage']" alt="John" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <app-bar-item
          @click.native="
            $router.push({ name: 'UserSettings', params: { type: 'profile' } })
          "
        >
          <v-list-item-title v-text="$t('settings')" />
        </app-bar-item>
        <app-bar-item
          v-if="false"
          @click.native="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-list-item-title
            v-text="
              $t(
                $vuetify.theme.dark ? 'change_theme_light' : 'change_theme_dark'
              )
            "
          />
        </app-bar-item>
        <app-bar-item @click.native="$router.push({ name: 'help' })">
          <v-list-item-title v-text="$t('help')" />
        </app-bar-item>
        <app-bar-item
          @click.native="$store.commit('app/SET_CHANGE_USER', true)"
        >
          <v-list-item-title v-text="$t('change_user')" />
        </app-bar-item>
        <app-bar-item @click.native="logout">
          <v-list-item-title v-text="$t('logout')" />
        </app-bar-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {},
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
    DropdownLanguage: () => import("@/components/ui/DropdownLanguage"),
    NotificationItem: () => import("@/components/notifications/Item"),
    NotificationsList: () => import("@/components/notifications/List"),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    window: {
      handler() {
        //console.log("rezise");
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch("auth/getNotifications");
    this.$store.dispatch("auth/checkActive");
    this.$store.dispatch("auth/checkBoxActive");
    this.$store.dispatch("auth/getSettings");
    console.log("userAgent", window.navigator);
    console.log(this.$browserDetect);
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
    menu: false,
  }),

  computed: {
    ...mapState("app", ["drawer", "hover"]),
    ...mapState("auth", ["notifications"]),
    showBack() {
      if (this.$vuetify.breakpoint.xs) {
        switch (this.$route.name) {
          case "Login":
          case "Dashbord":
          case "calendar":
          case "email":
          case "tattooerList":
          case "userList":
          case "customerList":
          case "studioList":
          case "boxList":
          case "appointmentList":
          case "inventoryList":
          case "movementList":
          case "economyView":
          case "filesView":
            return false;
        }
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions("auth", ["markAllNotification"]),
    allMark() {
      this.$confirm(this.$t("markAllNotification"), "", "").then(() => {
        this.markAllNotification();
        this.$store.dispatch("auth/getNotifications");
      });
    },
    btnMenu() {
      //console.log(window.innerWidth);
      if (window.innerWidth <= 1263) {
        this.setHover(!this.hover);
        this.setDrawer(false);
      } else this.setDrawer(!this.drawer);
    },
    ...mapMutations("app", {
      setDrawer: "SET_DRAWER",
      setHover: "SET_HOVER",
    }),
    profile() {
      //console.log("profile");
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({
          name: "Login",
        });
      });
    },
  },
};
</script>

<style lang="sass">
.v-badge
  &.inset_10 .v-badge__badge
    inset: auto auto calc(100% - 4px) calc(100% - 10px) !important

  &.inset_100 .v-badge__badge
    inset: auto auto calc(100% - 4px) calc(100% - 15px) !important
</style>
