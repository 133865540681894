var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"absolute":"","app":"","flat":"","height":"75"}},[(_vm.showBack && _vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mr-2",staticStyle:{"box-shadow":"none"},attrs:{"fab":"","outlined":"","x-small":"","color":"primary"},nativeOn:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{staticClass:"mt-0 ml-1"},[_vm._v("mdi-arrow-left")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticStyle:{"box-shadow":"none"},attrs:{"fab":"","x-small":"","color":"primary"},nativeOn:{"click":function($event){return _vm.btnMenu()}}},[_c('v-icon',{staticClass:"mt-0",attrs:{"color":"white"}},[_vm._v("mdi-dots-horizontal")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"primary--text ml-5",staticStyle:{"text-transform":"uppercase","border-bottom":"2px solid"},domProps:{"textContent":_vm._s(
      _vm.$t(
        _vm.$vuetify.breakpoint.xsOnly
          ? 'routes_xs.' + _vm.$route.name
          : 'routes.' + _vm.$route.name
      )
    )}}),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',[(_vm.$store.getters['auth/rrss'].hasOwnProperty('instagram'))?_c('a',{attrs:{"href":("https://www.instagram.com/" + (_vm.$store.getters['auth/rrss'].instagram) + "/"),"target":"blank"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"primary","depressed":""}},[_c('v-icon',[_vm._v("mdi-instagram")])],1)],1):_vm._e(),(_vm.$store.getters['auth/rrss'].hasOwnProperty('tiktok'))?_c('a',{attrs:{"href":("https://www.tiktok.com/@" + (_vm.$store.getters['auth/rrss'].tiktok) + "/"),"target":"blank"}},[_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"fab":"","x-small":"","color":"primary","depressed":""}},[_c('v-icon',[_vm._v("$tik_tok")])],1)],1):_vm._e(),(_vm.$store.getters['auth/rrss'].hasOwnProperty('twitter'))?_c('a',{attrs:{"href":("https://www.twitter.com/" + (_vm.$store.getters['auth/rrss'].twitter) + "/"),"target":"blank"}},[_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"fab":"","x-small":"","color":"primary","depressed":""}},[_c('v-icon',[_vm._v("mdi-twitter")])],1)],1):_vm._e(),(_vm.$store.getters['auth/rrss'].hasOwnProperty('facebook'))?_c('a',{attrs:{"href":("https://www.facebook.com/" + (_vm.$store.getters['auth/rrss'].facebook) + "/"),"target":"blank"}},[_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"fab":"","x-small":"","color":"primary","depressed":""}},[_c('v-icon',[_vm._v("$facebook")])],1)],1):_vm._e()]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_c('dropdown-language')],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","absolute":"","origin":"top right"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"min-width":"0","fab":"","outlined":"","x-small":""}},'v-btn',attrs,false),on),[(_vm.$store.getters['auth/notificationdCount'] > 0)?_c('v-badge',{class:[
            {
              inset_10:
                _vm.$store.getters['auth/notificationdCount'] > 9 &&
                _vm.$store.getters['auth/notificationdCount'] < 100,
            },
            { inset_100: _vm.$store.getters['auth/notificationdCount'] > 99 } ],attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.$store.getters["auth/notificationdCount"])+" ")]},proxy:true}],null,true)},[_c('v-icon',{staticStyle:{"margin-left":"3px","margin-top":"0px"}},[_vm._v("mdi-bell")])],1):_c('v-icon',{staticStyle:{"margin-left":"3px","margin-top":"0px"}},[_vm._v(" mdi-bell ")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_vm._l((_vm.notifications.slice(0, 4)),function(n,i){return _c('notification-item',{key:("item-" + i),style:([
          {
            'max-width': _vm.$vuetify.breakpoint.xsOnly ? '330px' : '1000px',
          } ]),attrs:{"item":n}})}),_c('v-list-item',[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","small":"","left":""},on:{"click":function($event){return _vm.$router.push({ name: 'notifications' })}}},[_vm._v(" "+_vm._s(_vm.$t("see_more"))+" "),(_vm.notifications.length > 4)?_c('v-span',{staticClass:"pl-1"},[_vm._v(" +"+_vm._s(_vm.notifications.length - 4)+" ")]):_vm._e()],1)],1),(_vm.notifications.length > 0)?_c('v-col',{staticClass:"pa-0",staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","small":"","right":""},on:{"click":function($event){return _vm.allMark()}}},[_vm._v(" "+_vm._s(_vm.$t("notifications.read"))+" ")])],1):_vm._e()],1)],1)],2)],1),_c('v-menu',{staticStyle:{"width":"100px !important"},attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"min-width":"0","fab":"","text":"","small":""}},on),[_c('v-avatar',{attrs:{"size":"36"}},[_c('img',{attrs:{"src":_vm.$store.getters['auth/userImage'],"alt":"John"}})])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_c('app-bar-item',{nativeOn:{"click":function($event){return _vm.$router.push({ name: 'UserSettings', params: { type: 'profile' } })}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('settings'))}})],1),(false)?_c('app-bar-item',{nativeOn:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
            _vm.$t(
              _vm.$vuetify.theme.dark ? 'change_theme_light' : 'change_theme_dark'
            )
          )}})],1):_vm._e(),_c('app-bar-item',{nativeOn:{"click":function($event){return _vm.$router.push({ name: 'help' })}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('help'))}})],1),_c('app-bar-item',{nativeOn:{"click":function($event){return _vm.$store.commit('app/SET_CHANGE_USER', true)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('change_user'))}})],1),_c('app-bar-item',{nativeOn:{"click":function($event){return _vm.logout($event)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('logout'))}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }